<template>
	<div>
		<app-app-header v-model="indexStore.isHeaderVisible"
						:title="$t(`label.${props.type}`)"
						:color="$config.public.colors[props.type]"
						:item-link="props.itemLink"
						hide-on-scroll/>

		<app-content>
			<app-header-toolbar :on-top="!indexStore.isHeaderVisible" :item-link="props.itemLink" :color="color">
				<div class="w-full h-full flex items-center">
					<form-input name="search"
								v-model="filterData.search"
								:icon="icons.search"
								type="search"
								enterkeyhint="search"
								hide-details
								inverted
								clearable
								class="flex-grow-x-1"
								@focus="handleSearchInputFocus"
								@blur="handleSearchInputBlur"
								:placeholder="$t('placeholder.search')"/>

					<btn class="ml-8 sm:min-w-320" @click="openFilterModal" :color="filterExists && modalFilterExists ? color : null">
						<icon :icon="icons.filter"/>
						<span class="none sm:block">{{ $t('action.filter') }}</span>
					</btn>
				</div>

				<template #tools>
					<chip class="mr-8" small clickable exact replace :color="!filterExists ? color : null" :to="{path: $route.path, query: {...$route.query, flavor: undefined}}">
						{{ $t('label.all') }}
					</chip>
					<chip v-for="flavorOption in flavorOptions" :key="flavorOption.value" class="mr-8" small exact replace
						  :color="filterData.flavor === flavorOption.value ? color : null" :to="{path: $route.path, query: {...$route.query, flavor: flavorOption.value}}">
						{{ flavorOption.text }}
					</chip>
				</template>

			</app-header-toolbar>

			<page-content background mobile-tight>

				<container>
					<template v-if="filterExists">
						<lazy-missing-info v-if="!$auth.loggedIn && filterData.flavor === 'stars'"
										   :text="$t('missing.myself.article_following')"
										   card
										   :button="$t('action.club.index')"
										   :to="$web('clubs.index')"
										   class="min-h-200"/>
						<lazy-missing-info v-else-if="!$auth.loggedIn && filterData.flavor === 'sports'"
										   :text="$t('missing.myself.article_sports')"
										   card
										   :button="$t('view.my.profile.manage.sport_types.title')"
										   :to="$auth.loggedIn ? $web('my.profile.manage.sport_types') : {path: $web('auth.login'), query: {redirect: $web('my.profile.manage.sport_types')}}"
										   class="min-h-200"/>

						<data-loader v-else :url="`/v1/view/articles`"
									 :params="filterParams"
									 :filter="filterData"
									 :auth="selectedFlavorOption ? !selectedFlavorOption.guest : false"
									 :as-guest="selectedFlavorOption ? selectedFlavorOption.guest : true"
									 :persist-filter-keys="[...DIRECT_FILTER_PROPS, ...MODAL_FILTER_PROPS]"
									 on-scroll @filtering="handleApplyingFilter">

							<template #loading>
								<row tight>
									<column cols="12" sm="6" md="4" v-for="articleLoader in filterParams.limit" :key="'article-loader-' + articleLoader">
										<article-card-skeleton/>
									</column>
								</row>
							</template>

							<template v-slot="{data: articles}">
								<row tight v-adapt:height="{selector: '.article-card', xs: 12, sm: 6, md: 4, lg: 4, xl: 4}">
									<column cols="12" sm="6" md="4" v-for="(article, articleKey) in articles" :key="'article-preview-' + articleKey">
										<article-card :article="article" class="article-card"/>
									</column>
								</row>
							</template>

							<template #missingsearch>
								<lazy-missing-info :text="$t('missing.article.search')" card class="min-h-200"/>
							</template>
							<template #missing>
								<lazy-missing-info :text="$t('missing.article.index')" card class="min-h-200"/>
							</template>
						</data-loader>
					</template>
					<template v-else-if="sections.length">
						<section v-for="section in sections" :key="`${section.type}-${section.key}`" class="mb-24">
							<div class="flex items-center justify-between mb-24">
								<h2 class="mb-0">{{ section.title }}</h2>
								<span @click="filterBySection(section)" :style="{color: color}" class="text-sm cursor-pointer">{{ $t('action.show_all') }}</span>
							</div>

							<div class="mx--16 md:mx-0">
								<slider :show-arrows="$device.isDesktop" visible-arrows @update:modelValue="value => handleChangedSectionSlide(section, value)" full-width-spaced>
									<slider-item v-for="(article, articleIndex) in section.articles" :key="`${section.key}-${article.id}`" :value="articleIndex">
										<article-card :article="article" class="article-card h-440 w-304"/>
									</slider-item>
								</slider>
							</div>
						</section>
						<client-only>
						<span v-if="currentlyLoadedSectionType && currentlyLoadedSectionType !== loadableSectionTypes[loadableSectionTypes.length - 1]" class="block w-full h-1"
							  v-intersect="{handler: loadNextSections, options: {rootMargin: `200px`}}"/>
						</client-only>

					</template>
					<lazy-missing-info v-else :text="$t('missing.article.search')" card class="min-h-200"/>

				</container>

			</page-content>
		</app-content>

		<modal v-model="showFilterModal" :title="$t('action.filter')" small :show-close-button="false">

			<lazy-form-sport-type-select :placeholder="$t('label.sport_type')"
										 inverted
										 multiple name="sport" v-model="modalFilterData.sport"/>

			<form-remote-select name="category" :action="$api('article_categories.index')"
								:data="{type: type}"
								as-guest
								v-model="modalFilterData.category"
								clearable
								inverted
								text-prop="title"
								value-prop="_key"
								:label="$t('label.category')"/>

			<form-club-select name="club"
							  v-model="modalFilterData.club"
							  clearable
							  inverted
							  value-prop="_key"
							  :label="$t('label.club_team_company')"/>

			<template #footer>
				<wrapper class="p-16 border-box border-t" stretch>
					<btn v-if="modalFilterExists" @click="resetFilterModal" large>
						<icon :icon="icons.close"/>
						<span>{{ $t('action.reset') }}</span>
					</btn>
					<btn @click="submitFilterModal" large color="success">
						<icon :icon="icons.submit"/>
						<span>{{ $t('action.filter') }}</span>
					</btn>
				</wrapper>
			</template>
		</modal>
	</div>
</template>

<script setup lang="ts">

import {useIndexStore} from "../../store";
import {mdiFilterVariant} from "@mdi/js";
import {closeIcon, checkIcon} from "@spoferan/nuxt-spoferan/icons";
import {cloneObject, scrollToPosition} from "@spoferan/spoferan-ts-core";
import {submitIcon} from "@spoferan/nuxt-spoferan/icons";
import {searchIcon} from "@spoferan/nuxt-spoferan/icons";

const props = defineProps<{
	type: string,
	itemLink?: { to: string, text: string, color?: string, button?: boolean },
}>();

const DIRECT_FILTER_PROPS = ['search', 'flavor'];
const MODAL_FILTER_PROPS = ['sport', 'category', 'club'];

const {t} = useI18n();
const indexStore = useIndexStore();
const {handleSearchInputFocus, handleSearchInputBlur, handleApplyingFilter} = useSearchPage();
const route = useRoute();
const router = useRouter();
const {$apiFetch, $device, $auth} = useNuxtApp();
const config = useRuntimeConfig();

const color = config.public.colors[props.type];

const icons = {
	close: closeIcon,
	submit: submitIcon,
	filter: mdiFilterVariant,
	search: searchIcon,
};

const flavorOptions = [
	{value: 'latest', text: t('label.new'), guest: true},
	{value: 'trending', text: t('label.recent'), guest: true},
	// {value: 'popular', text: t('label.popular'), guest: true},  // TODO: Temp. disabled until more articles exist as otherwise this will show the same articles as other sections
	// {value: 'stars', text: t('label.my_followings'), guest: false},  // TODO: Temp. disabled until more articles exist as otherwise this will show the same articles as other sections
	{value: 'sports', text: t('label.my_sport_types'), guest: false},
];

const articlesPerSection = $device.isDesktop ? 9 : 5;

const currentlyLoadedSectionType = ref(null);

// The initial sections to display when not filtering
const sections = ref([]);

// The filter data to send to the server that can be modified by the user
const filterData = ref([...DIRECT_FILTER_PROPS, ...MODAL_FILTER_PROPS].reduce((obj: {[key: string]: any}, prop) => {
	obj[prop] = route.query[prop];
	return obj;
}, {}));

// The filter data the user can modify within the filter modal
// Separated from filterData to prevent instant filter submits
const modalFilterData = ref(MODAL_FILTER_PROPS.reduce((obj: {[key: string]: any}, prop) => {
	obj[prop] = route.query[prop];
	return obj;
}, {}));

const showFilterModal = ref(false);

const selectedFlavorOption = computed(() => {
	return filterData.value.flavor ? flavorOptions.find(option => option.value === filterData.value.flavor) : null;
});

// The filter data that can not be modified by the user
const filterParams = {
	limit: 15,
	type: props.type
};

const filterExists = computed(() => {
	return Object.values(filterData.value).filter(value => value && value.length).length > 0;
});

const modalFilterExists = computed(() => {
	return Object.keys(modalFilterData.value).filter(key => key !== 'search' && modalFilterData.value[key] && modalFilterData.value[key].length).length > 0;
});

const loadableSectionTypes = computed(() => {
	const types = ['recent'];
	if ($auth.token) {
		types.push('personalized');
	}
	types.push('categories');

	return types;
});

watch(filterExists, (newValue) => {
	if (!newValue && !sections.value.length) {
		loadSections(loadableSectionTypes.value[0]);
		handleApplyingFilter();
	} else {
		handleApplyingFilter();
	}
});

watch(() => filterData.flavor, () => {
	handleApplyingFilter();
});

watch(() => route.query.flavor, (flavor) => {
	filterData.value.flavor = flavor;
});

async function loadSections(type) {
	const data = await useApiFetch(`/v1/view/articles/sections`, {
		guest: type !== 'personalized',
		params: {
			section: type,
			type: props.type,
			limit: articlesPerSection
		}
	}).finally(() => {
		currentlyLoadedSectionType.value = type;
	});
	sections.value.push(...data.data);
}

function loadNextSections() {
	// Prevent initial duplicated load
	if (!sections.value.length || !currentlyLoadedSectionType.value) {
		return;
	}

	const currentLoadableSectionTypesIndex = loadableSectionTypes.value.indexOf(currentlyLoadedSectionType.value);
	if (currentLoadableSectionTypesIndex < loadableSectionTypes.value.length - 1) {
		loadSections(loadableSectionTypes.value[currentLoadableSectionTypesIndex + 1]);
	}
}

function openFilterModal() {
	modalFilterData.value = cloneObject(filterData.value);
	nextTick(function () {
		showFilterModal.value = true;
	});
}

function submitFilterModal() {
	filterData.value = cloneObject(modalFilterData.value);
	showFilterModal.value = false;
}

function resetFilterModal() {
	// All filter props have to exist to be reactive
	[...DIRECT_FILTER_PROPS, ...MODAL_FILTER_PROPS].forEach(prop => {
		modalFilterData.value[prop] = null;
	});
	submitFilterModal();
}

function resetFilter() {
	// All filter props have to exist to be reactive
	[...DIRECT_FILTER_PROPS, ...MODAL_FILTER_PROPS].forEach(prop => {
		filterData.value[prop] = null;
	});
}

function filterBySection(section) {
	if (section.type === 'flavor') {
		filterData.value.flavor = section.key;
	} else if (section.type === 'category' || section.type === 'club') {
		modalFilterData.value[section.type] = section.key;
		submitFilterModal();
	} else if (section.type === 'sport') {
		modalFilterData.value[section.type] = [section.key];
		submitFilterModal();
	}
}

function handleChangedSectionSlide(section, index) {
	const displayedSlidesCount = $device.isDesktop ? 3 : 1;
	const lastDisplayedSlideIndex = index + displayedSlidesCount;
	if ((section.articles.length - 2) === lastDisplayedSlideIndex) {
		loadNextArticlesForSection(section);
	}
}

async function loadNextArticlesForSection(section) {
	const hasMoreArticles = section.articles.length % articlesPerSection === 0;
	if (!hasMoreArticles) {
		return;
	}

	const currentPage = (section.articles.length / articlesPerSection);

	const params = {
		limit: articlesPerSection,
		page: currentPage + 1,
		type: props.type,
		simple_paginated: true
	};
	params[section.type] = section.key;

	const selectedFlavorOption = section.type === 'flavor' ? flavorOptions.find(option => option.value === section.key) : null;

	$apiFetch(`/v1/view/articles`, {
		guest: selectedFlavorOption ? selectedFlavorOption.guest : true,
		params: params
	}).then(response => {
		section.articles.push(...response.data);
	});
}

if (!filterExists.value) {
	await loadSections(loadableSectionTypes.value[0]);
}

</script>

